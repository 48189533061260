import { useState, useCallback } from 'react';
import { useAuth } from '../context/authContext';
import { VehicleStatus } from '@solar-data/schemas/lib/owned-by/solar';
import queryRange from '../utils/queryRange';

export function useVehicleStatus() {
  const { logout, getHeaders } = useAuth();
  const [error, setError] = useState<string | undefined>(undefined);

  const queryVehicleStatus = useCallback(
    async (
      fleetSlug: string,
      start: moment.Moment | undefined,
      end: moment.Moment | undefined,
    ): Promise<VehicleStatus[]> => {
      try {
        const resp = await fetch(`/api/fleets/${fleetSlug}/vehicleStatus${queryRange(start, end)}`, {
          headers: await getHeaders(),
        });

        if (resp.ok) {
          return await resp.json();
        } else if (resp.status === 401) {
          logout();
          window.location.href = '#/login';
          throw new Error('Unauthorized');
        } else {
          throw new Error(await resp.text());
        }
      } catch (e) {
        setError(`${e}`);
        throw e;
      }
    },
    [getHeaders, logout],
  );

  return { queryVehicleStatus, error };
}

import { useState, useCallback } from 'react';
import { useAuth } from '../context/authContext';
import moment from 'moment';
import { EnergyEarnings } from '@solar-data/schemas/lib/owned-by/solar';
import queryString from '../utils/queryString';

export function useEnergyEarnings() {
  const [error, setError] = useState(undefined as string | undefined);

  const { logout, getHeaders } = useAuth();

  const energyEarningsQuery = useCallback(
    async (
      fleetSlug: string,
      vehicleId: number | undefined,
      start: moment.Moment | undefined,
      end: moment.Moment | undefined,
      by: 'day' | 'hour' | 'month' = 'day',
    ): Promise<EnergyEarnings | undefined> => {
      try {
        const resp = await fetch(
          `/api/fleets/${fleetSlug}${vehicleId ? `/vehicles/${vehicleId}` : ''}/energyEarnings${queryString(
            start,
            end,
            by,
          )}`,
          {
            headers: await getHeaders(),
          },
        );

        if (resp.ok) {
          return await resp.json();
        } else if (resp.status === 401) {
          logout();
          window.location.href = '#/login';
        } else {
          throw new Error(await resp.text());
        }
      } catch (e) {
        setError(`${e}`);
        throw e;
      }
    },
    [getHeaders, logout],
  );

  return { energyEarningsQuery, error };
}

import { useState, useEffect, useCallback, useMemo } from 'react';
import { useFleets } from '../context/fleetContext';
import { useVehicleStatus } from '../fetchers/vehicleStatus';
import { useEnergyEarnings } from '../fetchers/energy';
import getDefaultTimeRange from '../utils/getDefaultTimeRange';
import { UiVehicleOperatingMode } from '../fetchers/vehicleOperatingModes';
import { VehicleStatus } from '@solar-data/schemas/lib/owned-by/solar';
import { EnergyEarnings } from '@solar-data/schemas/lib/owned-by/solar';

const useVehicleData = (
  vehicleId: number | undefined,
  vehicleOperatingModes: UiVehicleOperatingMode[] | undefined,
  period?: string,
) => {
  const { currentFleet } = useFleets();
  const { queryVehicleStatus } = useVehicleStatus();
  const { energyEarningsQuery } = useEnergyEarnings();

  // set default time mode to month so that only data for the current month is loaded
  const defaultRange = getDefaultTimeRange(period || 'month');
  const [vehicleStatusQueryResult, setVehicleStatusQueryResult] = useState<VehicleStatus[] | undefined>(undefined);
  const [energyEarningsQueryResult, setEnergyEarningsQueryResult] = useState<EnergyEarnings | undefined>(undefined);
  const [range, setRange] = useState(defaultRange);
  const [timeMode, setTimeMode] = useState('month');

  // Memoize the query functions
  const memoizedEnergyQuery = useCallback(async (fleet, vehicleId, start, end, mode) => {
    try {
      const result = await energyEarningsQuery(fleet, vehicleId, start, end, mode);

      setEnergyEarningsQueryResult(result);
    } catch (error) {
      return null;
    }
  }, []);

  const memoizedVehicleStatusQuery = useCallback(async (fleet, start, end) => {
    try {
      const result = await queryVehicleStatus(fleet, start, end);

      setVehicleStatusQueryResult(result);
    } catch (error) {
      return null;
    }
  }, []);

  const queryType = useMemo(() => {
    return timeMode === 'month' ? 'day' : 'month';
  }, [timeMode]);

  const vehicle = useMemo(() => {
    return vehicleId === -1 ? undefined : vehicleId;
  }, [vehicleId]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      await memoizedEnergyQuery(currentFleet, vehicle, range[0], range[1], queryType);
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, [currentFleet, range, vehicleId, vehicleOperatingModes]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      await memoizedVehicleStatusQuery(currentFleet, range[0], range[1]);
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, [currentFleet, range]);

  // Use useCallback for setTimeMode
  const handleTimeModeChange = useCallback((mode) => {
    setTimeMode(mode);
  }, []);

  return {
    range,
    setRange,
    timeMode,
    handleTimeModeChange,
    energyData: energyEarningsQueryResult,
    vehicleStatusData: vehicleStatusQueryResult,
  };
};

export default useVehicleData;

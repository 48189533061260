const de = {
  "solar": {
    "boolean": {
      "false": "Nein",
      "true": "Ja"
    },
    "cancel": "Abbrechen",
    "myFleet": {
      "energyEarnings": "Energiegewinn",
      "installedCapacity": "Installierte Leistung",
      "kWInstalled": "kW installiert",
      "kWpInstalled": "Ingesamt installierte kWp",
      "latestSignal": "Letzte Aktualisierung",
      "title": "Alle Fahrzeuge",
      "typeToSearch": "Suchen...",
      "vehicles": "Fahrzeuge",
      "viewDetails": "Details anzeigen"
    },
  "dashboard": {
      "avgPower": "Durchschnittliche Leistung",
      "backToAllVehicles": "Zurück zur gesamten Flotte",
      "dailyAverageEnergyEarnings": "Täglicher durchschnittlicher Energiegewinn",
      "dailyAverageUsage": "Tägliche durchschnittliche Nutzung des PV-Systems",
      "details": {
        "dailyEnergyEarnings": {
          "all": "Alle auswählen",
          "editOperatingMode": "Bearbeiten",
          "filters": {
            "date": "Zeitbereich:",
            "kWh": "Energiegewinn (kWh):",
            "title": "Filter ({{active}} aktiv)",
            "to": "bis"
          },
          "title": "Täglicher Energiegewinn"
        },
        "filters": {
          "addFilter": "Daten am {{day}} als ungültig markieren",
          "editFleetFilter": "Daten am {{day}} sind ungültig für alle Fahrzeuge",
          "editVehicleFilter": "Daten am {{day}} sind ungültig für {{vehicleName}}",
          "fleetFiltered": "Signals sind für alle Fahrzeuge ungültig",
          "markFleetInvalid": "Für alle Fahrzeuge als ungültig markieren",
          "markValid": "Als gültig markieren",
          "markVehicleInvalid": "Für {{vehicleName}} als ungültig markieren",
          "notFiltered": "Signale sind gültig",
          "reason": "Begründung",
          "updateReason": "Begründung aktualisieren",
          "vehicleFiltered": "Signale sind für dieses Fahrzeug ungültig"
        },
        "hourlyOutput": "Energiegewinn (pro Stunde)",
        "hourlyOutputDay": "Tag auswählen:",
        "kWInstalled": "kW installiert",
        "kWpInstalled": "kWp installiert",
        "last30Days": "Täglicher Energiegewinn",
        "latestSignal": "Letzte Aktualisierung",
        "information": "Der Betriebsmodus zeigt an, ob Ihr System an einem bestimmten Datum aktiv war oder nicht. Bitte wählen Sie ein oder mehrere Daten im Kalender unten aus, um zu markieren, wann Ihr Fahrzeug außer Betrieb war. Diese Tage werden Ihnen dann als außer Betrieb angezeigt und auch von den Berechnungen ausgeschlossen.",
        "operatingMode": {
          "tab": "Betriebsdetails bearbeiten",
          "reason": "Grund / Kommentar (optional)",
          "title": "Betriebsmodus für {{vehicleName}} am {{day}}",
          "titleMultipleDays": "Betriebsmodus für {{vehicleName}} an ausgewählten Tagen"
        },
        "title": "Details"
      },
      "dashboardAdminCard": {
        "title": "Admin-Flotte",
        "text": "Dies ist eine spezielle Flotte, die keine Fahrzeuge, sondern nur Benutzer enthält.",
        "extraText": "Benutzer, die dieser Flotte hinzugefügt werden, haben Zugriff auf alle anderen Flotten.",
        "button": "Admin-Benutzer und Flotten verwalten"
      },
      "downloadCsv": {
        "day": "Tag",
        "energyEarningsWh": "Energiegewinn (Wh)",
        "label": "CSV herunterladen",
        "tooltip": "Ausgewählte Werte als CSV-Datei herunterladen"
      },
      "duration": {
        "allTime": "Gesamt",
        "month": "Monat",
        "title": "Zeitraum:",
        "year": "Jahr"
      },
      "energy": "Energie",
      "excluded": "Nicht in der Statistik berücksichtigt",
      "hours": "Stunden",
      "includedOperatingModes": "Betriebszustände:",
      "kWh": "kWh",
      "monthlyAverageEnergyEarnings": "Monatlicher durchschnittlicher Energiegewinn",
      "noVehicles": "Diese Flotte beinhaltet noch keine Fahrzeuge.",
      "peakEnergyEarnings": "Maximaler Energiegewinn",
      "minNonZeroEnergyEarnings": "Niedrigster Energiegewinn*",
      "summary": "Zusammenfassung",
      "title": "Dashboard",
      "totalEnergyEarnings": "Gesamter Energiegewinn",
      "vehicles": "Fahrzeuge",
      "weeklyAverageEnergyEarnings": "Wöchentlicher durchschnittlicher Energiegewinn",
      "date": "{{day}}.{{month}}.{{year}}",
      "warning": "*Hinweis: Der Tag bezeichnet die niedrigste Energiemenge, die aus dem System oberhalb von null kWh gewonnen wird."
    },
    "errors": {
      "confirm": "Ok",
      "errorLoadingData": {
        "text": "Hoppla! Es ist ein unerwarteter Fehler beim Laden der Seite aufgetreten. Bitte versuche es erneut.",
        "title": "Fehler beim Laden"
      },
      "errorSavingData": {
        "text": "Hoppla! Deine Daten konnten leider nicht gespeichert werden. Bitte versuche es erneut.",
        "title": "Fehler beim Speichern"
      },
      "loginError": {
        "text": "Hm, der Login ist leider fehlgeschlagen. Bitte versuche es erneut.",
        "title": "Fehler beim Login"
      },
      "showDetails": "Details anzeigen",
      "access_denied": {
        "title": "Kein Zugriff verfügbar",
        "text": "Sie haben keinen Zugriff für diese Seite."
      }
    },
    "euros": {
      "delimiter": ".",
      "format": "{{amount}} €",
      "separator": ","
    },
    "faqs": {
      "edit": {
        "answer": "Antwort ({{lang}})",
        "deleteButton": "Löschen",
        "editGroup": "Gruppe bearbeiten",
        "editQuestion": "Frage bearbeiten",
        "groupTitle": "Titel ({{lang}})",
        "question": "Frage ({{lang}})"
      },
      "intro": "Willkommen im FAQ-Bereich! Hier finden Sie Antworten auf die häufigsten Fragen zu unserem Dashboard-Produkt. Egal, ob Sie neu auf der Plattform sind oder bereits Erfahrung haben, diese Ressource hilft Ihnen, sich zurechtzufinden und das Beste aus Ihrem Dashboard-Erlebnis herauszuholen. Stöbern Sie durch die Kategorien, um schnell die Informationen zu finden, die Sie benötigen. Wenn Sie die gesuchte Antwort nicht finden, können Sie sich gerne an unser Support-Team wenden.",
      "title": "Häufige Fragen",
      "header": "Häufige Fragen"
    },
    "fleet": {
      "add": "Neue Flotte",
      "addButton": "Neu",
      "deleteButton": "Löschen",
      "editButton": "Bearbeiten",
      "editTabTitle": "Flottendaten",
      "fleets": {
        "add": "Neue Flotte",
        "edit": "Flotte bearbeiten",
        "fleetType": "Typ",
        "fleetTypeBusKit": "Bus-Kit",
        "fleetTypeCampingworld": "Campingworld",
        "fleetTypeSvc3": "SVC3",
        "name": "Name",
        "slug": "Bezeichner",
        "title": "Flotten"
      },
      "name": "Name",
      "operatingModes": {
        "color": "Farbe",
        "edit": "Betriebsmodus bearbeiten",
        "isDefault": "Standard?",
        "name": "Name",
        "title": "Betriebszustände",
        "tab": "Betriebszustand",
        "useForStatistics": "In Statistiken verwenden?",
        "in_service": "In Bertieb",
        "out_of_service": "Außer Betrieb"
      },
      "slug": "Id",
      "users": {
        "add": "Neuer Benutzer",
        "edit": "Benutzer bearbeiten",
        "email": "E-Mail",
        "role": "Rolle",
        "role_admin": "Admin",
        "role_viewer": "Anzeigen",
        "title": "Benutzer",
        "adminUsers": "Admin-Benutzer",
        "editTabTitle": "Benutzerdaten",
      },
      "vehicles": {
        "add": "Neues Fahrzeug",
        "deviceIds": "Device-IDs",
        "deviceIdsExample": "z.B. 5E883901, 7A046BC1",
        "edit": "Fahrzeug bearbeiten",
        "installedCapacity": "Installierte Leistung",
        "installedCapacitykW": "Installierte Leistung (kW)",
        "installedCapacitykWp": "Installierte Leistung (kWp)",
        "location": "Ort",
        "model": "Model",
        "name": "Name",
        "numberPlate": "Kennzeichen",
        "operationMode": "Betriebsmodus",
        "route": "Route",
        "timeZone": "Zeitzone",
        "title": "Fahrzeuge",
        "validFrom": "Gültige Daten von",
        "validFromDay": "von {{from}}",
        "validity": "Datengültigkeit",
        "validUntil": "Gültige Daten bis",
        "validUntilDay": "bis {{until}}",
        "vehicleType": "Fahrzeugtyp",
        "installMetaDataType": "Installierter Typ",
        "installMetaDataMCUVersion": "Installierte MCU Version",
        "installMetaDataState": "Instellation Status",
      }
    },
    "myfleet": {
      "title": "Meine Flotte",
    },
    "vehicleDetails": {
      "title": "Fahrzeugdetails",
      "selectVehicle": "Fahrzeug:",
      "summary": "Zusammenfassung für %{vehicleName}",
      "hourlyEnergyEarnings": "Energiegewinn (pro Stunde)",
      "operationalDetails": "Betriebszustände",
      "latestSignal": "Letzte Aktualisierung",
      "edit": "Bearbeiten",
      "selectDates": "Datum auswählen:",
      "mode": "Modus",
      "included": "Einberechnet"
    },
    "weather": {
      "thunderstorm_with_light_rain": "gewitter mit leichtem regen",
      "thunderstorm_with_rain": "gewitter mit regen",
      "thunderstorm_with_heavy_rain": "gewitter mit starkem regen",
      "light_thunderstorm": "leichtes gewitter",
      "thunderstorm": "gewitter",
      "heavy_thunderstorm": "starkes gewitter",
      "ragged_thunderstorm": "zerfetztes gewitter",
      "thunderstorm_with_light_drizzle": "gewitter mit leichtem nieselregen",
      "thunderstorm_with_drizzle": "gewitter mit nieselregen",
      "thunderstorm_with_heavy_drizzle": "gewitter mit starkem nieselregen",
      "light_intensity_drizzle": "leichter nieselregen",
      "drizzle": "nieselregen",
      "heavy_intensity_drizzle": "starker nieselregen",
      "light_intensity_drizzle_rain": "leichter nieselregen",
      "drizzle_rain": "nieselregen",
      "heavy_intensity_drizzle_rain": "starker nieselregen",
      "shower_rain_and_drizzle": "schauerregen und nieselregen",
      "heavy_shower_rain_and_drizzle": "starker schauerregen und nieselregen",
      "shower_drizzle": "schauernieselregen",
      "light_rain": "leichter regen",
      "moderate_rain": "mäßiger regen",
      "heavy_intensity_rain": "starker regen",
      "very_heavy_rain": "sehr starker regen",
      "extreme_rain": "extremer regen",
      "freezing_rain": "gefrierender regen",
      "light_intensity_shower_rain": "leichter schauerregen",
      "shower_rain": "schauerregen",
      "heavy_intensity_shower_rain": "starker schauerregen",
      "ragged_shower_rain": "zerfetzter schauerregen",
      "light_snow": "leichter schnee",
      "snow": "schnee",
      "heavy_snow": "starker schnee",
      "sleet": "schneeregen",
      "light_shower_sleet": "leichter schneeregenschauer",
      "shower_sleet": "schneeregenschauer",
      "light_rain_and_snow": "leichter regen und schnee",
      "rain_and_snow": "regen und schnee",
      "light_shower_snow": "leichter schneeschauer",
      "shower_snow": "schneeschauer",
      "heavy_shower_snow": "starker schneeschauer",
      "mist": "nebel",
      "smoke": "rauch",
      "haze": "dunst",
      "sand_dust_whirls": "sand-/staubwirbel",
      "fog": "nebel",
      "sand": "sand",
      "dust": "staub",
      "volcanic_ash": "vulkanasche",
      "squalls": "böen",
      "tornado": "tornado",
      "clear_sky": "klarer himmel",
      "few_clouds": "wenige wolken: 11-25%",
      "scattered_clouds": "vereinzelte wolken: 25-50%",
      "broken_clouds": "aufgelockerte bewölkung: 51-84%",
      "overcast_clouds": "bedeckter himmel: 85-100%"
  },
    "settings": {
      "title": "Einstellungen",
    },
    "admin": {
      "title": "Admin",
    },
    "documents": {
      "docs": {
        "empty": "Es gibt bisher keine Dokumentation",
        "title": "Dokumentation",
        "view": "Dokument anzeigen",
      },
      "reports": {
        "empty": "Es gibt bisher keine Reports.",
        "menuTitle": "Berichte",
        "title": "Monatliche Berichte",
        "view": "Report anzeigen",
      },
      "fileName": "Dateiname",
      "month": "Monat",
      "latestFirst": "Neuste zuerst",
      "oldestFirst": "Älteste zuerst",
    },
    "footer": {
      "imprintLabel": "Impressum",
      "imprintLink": "https://sonomotors.com/de/imprint/",
      "logoutLabel": "Log out",
      "privacyLabel": "Datenschutzerklärung",
      "privacyLink": "https://sonomotors.com/de/privacy-policy/",
      "termsLabel": "AGB & Widerrufsrecht",
      "termsLink": "https://sonomotors.com/de/downloads/terms-and-conditions/",
      "whisteblowerLabel": "Whistleblower Hotline",
      "whisteblowerLink": "https://www.whistleblowerservices.com/sonogroup?language=de",
      "rightsReserved": "© Sono Motors 2024. All rights reserved.",
    },
    "login": {
      "emailAddress": "E-Mail-Adresse",
      "login": "Login",
      "loginCode": "Login-Code",
      "loginInstructions": "Um dich einzuloggen, gibst du nachstehend ganz einfach deine E-Mail-Adresse ein. Wir schicken dir dann umgehend einen Login-Link.",
      "loginLinkRequestedText": "Wir haben dir gerade eine E-Mail an {{email}} geschickt. Benutze den Link aus der E-Mail oder gib den Code hier ein um dich einzuloggen:",
      "loginLinkRequestedTitle": "Du hast Post!",
      "loginWithEmail": "Einloggen mit E-Mail",
      "pageTitle": "Login",
      "requestAgain": "Erneut anfordern",
      "requestLoginLink": "Login-Link anfordern",
      "wrongCode": "Verflixt, der eingegebene Code scheint nicht zu stimmen. Bitte versuche es erneut oder fordere einen neuen Code an.",
      "yourSonoAccount": "Dein Sono-Account"
    },
    "noFleets": {
      "text": "Du bist für keine Flotte freigeschaltet. Bitte prüfe, ob du die richtige E-Mail-Adresse verwendet hast oder bitte deine:n Flottenadministrator:in, dich hinzuzufügen.",
      "title": "Hier gibt es nichts zu sehen"
    },
    "ok": "Ok",
    "yes": "Ja",
    "no": "Nein",
    "typeToSearch": "Suche",
    "sortBy": "Sortieren",
    "viewAll": "Alle anzeigen",
    "menu": {
      "client": "Kunde",
      "logout": "Log out",
      "close": "Schließen",
    },
  }
};

export default de;
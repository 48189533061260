import moment from 'moment';

export default function queryString(
  start: moment.Moment | undefined,
  end: moment.Moment | undefined,
  by: 'day' | 'hour' | 'month' | undefined,
): string {
  let qs = [] as string[];
  if (start !== undefined) {
    qs.push(`start=${start.format('YYYY-MM-DD')}`);
  }
  if (end === undefined) {
    end = moment();
  }
  if (end !== undefined) {
    qs.push(`end=${end.format('YYYY-MM-DD')}`);
  }
  if (by !== undefined) {
    qs.push(`by=${by}`);
  }
  return qs.length > 0 ? `?${qs.join('&')}` : '';
}
